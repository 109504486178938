import React from 'react'
import PropTypes from 'prop-types'
import '../../styles/card.styles.sass'

const InfoCard = (props) => {
    return (<div className={'info-card'}>
        <img alt="Blog" src={props.info.image} />
        <div className={'content'}>
            <span> <span role="img" aria-label="Country flag emoji">{props.countryFlag}</span> {props.info.title} </span>
            <ul>
                {
                    props.info.tags.map((tag) => <li>{tag}</li>)
                }
            </ul>
            <p className={'description'}>
                {props.info.description}
            </p>
            <div className={'link'}>
                {props.info.readMore && <a href={props.info.readMoreLink} target="_blank" rel="noreferrer" style={{ marginRight: '1rem' }}>
                    {props.info.readMore}
                </a>
                }
                {props.info.link !== '#' && <a href={props.info.link} target="_blank" rel="noreferrer">
                    {props.info.button}
                </a>
                }
                {props.info.link === '#' &&
                    <a href="#">{props.info.button}</a>
                }
            </div>

        </div>
    </div>)
}

export default InfoCard

InfoCard.propTypes = {
    info: PropTypes.object.isRequired,
}