import { Link } from 'gatsby'
import React from 'react'
import { InfoCard } from '../components/card'
import Footer from '../components/footer'
import Layout from '../components/layout'
import NavigationBar from '../components/navigation-bar'
import Seo from '../components/seo'
import blogs from '../content/blog.content'
import '../styles/layout.styles.sass'
import MenuButton from "../components/menu-button"
import Menu from "../components/menu"

const BlogPage = () => {
    const [isOpen, setOpen] = React.useState(false)

    return <Layout>
        <Seo title="My blogs" description="I write blogs as a hobby." meta="fullstack, software, developer, amsterdam" />
        <MenuButton page={'blog-page-bar'} open={isOpen} onClickOperation={setOpen} />
        <Menu open={isOpen} onCloseOperation={setOpen} />
        <div className={`page-container blog-page-container ${!isOpen ? 'page-container-open' : 'page-container-close'}`}>
            <div className={'navigation-container-left'}>
                <Link to="/"><NavigationBar title={'About me'} page={'about-me'} /></Link>
                <Link to="/skill"><NavigationBar title={'My skills'} page={'skills'} /></Link>
            </div>
            <div className={'content-container'}>
                <div className={'layout-container'}>
                    <div className={'title blog-title'}>
                        <em>Blogs</em>
                    </div>
                    {
                        blogs.map((blog) => {
                            return <div className={'blog'} key={blog.title}>
                                <InfoCard info={blog} key={blog.title}/>
                            </div>
                        })
                    }

                </div>
                <Footer />
            </div>
            <div className={'navigation-container-right'}>
                <Link to="/projects"> <NavigationBar title={'Projects'} page={'projects'} /></Link>
                <Link to="/contact"><NavigationBar title={'Contact me'} page={'contact-me'} /></Link>
            </div>
        </div>
    </Layout>
}

export default BlogPage