import React from 'react'
import PropTypes from 'prop-types'
import '../../styles/card.styles.sass'

const ContactCard = ({ image, title, link }) => {
    return (
        <div className={'contact-card'}>
            <a href={link} target="_blank" rel="noreferrer">
                <div className={'content'}>
                    <img alt="Social" src={image} className={title.toLowerCase()} />
                    <div className={'name'}>{title}</div>
                </div>
            </a>
        </div>
    )
}

export default ContactCard

ContactCard.propTypes = {
    title: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
}