import React from 'react'
import PropTypes from 'prop-types'
import { arrayOf } from 'prop-types'
import '../../styles/card.styles.sass'

const SkillCard = ({ skills, title }) => {
    return (<div className={'skill-card'}>
        <div className={'content'}>
            <h3>{title}</h3>
            {
                skills.map((skill, index) => {
                    if (!skill.isSingle) return <div key={index} className={'row'}>
                        <div className={'item'}>
                            <div className={'level'}></div>
                            <span>{skill.left}</span>
                        </div>
                        {skill.right && <div className={'item'}>
                            <div className={'level'}></div>
                            <span>{skill.right}</span>
                        </div>}
                    </div>
                    else return <div className={'row'} key={index}>
                        <div className={'item'}>
                            <div className={'level'}></div>
                            <span>{skill.item}</span>
                        </div>
                    </div>
                })
            }
        </div>
    </div>)
}

export default SkillCard

SkillCard.propTypes = {
    title: PropTypes.string.isRequired,
    skills: arrayOf(PropTypes.object),
}