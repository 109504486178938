const blogs = [
    {
        title: '10 Year anniversary of Java 1.8',
        tags: ['Java', 'Java1.8', 'Programming', 'Technology'],
        image: 'https://firebasestorage.googleapis.com/v0/b/devayansarkar-97d7e.appspot.com/o/java1_8.jpg?alt=media&token=199e6e91-812e-4557-91a8-3c274b78f68b',
        link: 'https://medium.com/@devayansarkar/10-year-anniversary-of-java-1-8-953eca6b3a18',
        description: '18th March 2024, marks the 10 year anniversary of Java 1.8, popularly known as Java 8. A release so significant that it changed the trajectory of Java from being, “a remnant of the past” to the language that gets downloaded 1 billion times every year.',
        button: 'Read blog'
    },
    {
        title: 'How to create multi module NodeJs applications.',
        tags: ['NodeJs', 'NPM', 'Javascript'],
        image: 'https://firebasestorage.googleapis.com/v0/b/devayansarkar-97d7e.appspot.com/o/multi-module.jpeg?alt=media&token=accd1174-6f38-40ff-bee8-83a1db246399',
        link: 'https://devayansarkar.medium.com/how-to-create-multi-module-nodejs-applications-9aff2d73dc17',
        description: 'Multi module projects are a common norm in jvm applications. Most of the enterprise applications would have it . It has its pros and cons. Let’s look at how we can implement multi module applications in NodeJs and what are its benefits.',
        button: 'Read blog'
    },
    {
        title: 'Building an Issue tracker application from scratch',
        tags: ['Vue.js', 'Cypress.io', 'Ruby on Rails', 'Postgres', 'Heroku'],
        image: 'https://firebasestorage.googleapis.com/v0/b/devayansarkar-97d7e.appspot.com/o/blog-image.jpg?alt=media&token=24551848-b386-41b3-862c-baf292c76f13',
        link: 'https://devayansarkar.medium.com/building-an-issue-tracker-application-from-scratch-77765240c1a8',
        description: 'This blog is about how I built an “Issue tracker” application from scratch, the tools and technology I chose (and why) , the steps involved and of course my learnings. Have fun reading.',
        button: 'Read blog'
    },
    {
        title: 'Creating Material UI themes in React',
        tags: ['Javascript', 'React', 'Material UI'],
        image: 'https://miro.medium.com/max/1400/1*sm48BgV9XTonX9CYcUE-VA.png',
        link: 'https://medium.com/javascript-in-plain-english/creating-material-ui-themes-in-react-cc839c9ee683',
        description: 'ReactJs coupled with Material UI is one of most popular choices to build modern web pages. This blog is about creating reusable components that can be styled differently using Material themes.',
        button: 'Read blog'
    },
    {
        title: 'GraphQL using Firebase',
        tags: ['Firebase', 'GraphQL', 'Serverless'],
        image: 'https://miro.medium.com/max/2000/1*yiC954vKKhFrVG0Cixof0Q.png',
        link: 'https://medium.com/swlh/graphql-server-using-firebase-97c9ec101948',
        description: 'Firebase is comprehensive app development platform that allows you to build your apps at scale. And with servless you can build web scale apps at a fraction of cost, almost free. This blog shows how we can run a GraphQL server on a serverless function.',
        button: 'Read blog'
    },
    {
        title: 'Microservices may not be the answer you need',
        tags: ['Microservices', 'Team'],
        image: 'https://miro.medium.com/max/1400/1*hRDXaItbEb8YrkUI6yTECg.jpeg',
        link: 'https://devayansarkar.medium.com/microservices-may-not-be-the-answer-you-need-e0d7cffe599',
        description: 'The world of microservices is fascinating. Microservices is the hot thing every team is doing. But at times it may not be the right choice to make. This blog is about some common situations where microservices may not be the answer.',
        button: 'Read blog'
    },
    {
        title: 'Is Deno replacement of NodeJs ?',
        tags: ['DenoJS', 'Javascript'],
        image: 'https://miro.medium.com/max/1400/1*MBFfIuHbtTjzGOkrNYgX8g.png',
        link: 'https://devayansarkar.medium.com/deno-says-hello-world-6eb1aca7f75d',
        description: 'Creator of Nodejs, Ryan Dahl comes up with another framework, DenoJs. This is his attempt to correct some of the decisions that he feels were incorrect in Nodejs. This blog is about the difference that Deno has with Node.',
        button: 'Read blog'
    },
    {
        title: 'What is the best IDE for coding?',
        tags: ['IDE', 'Coding'],
        image: 'https://miro.medium.com/max/3944/1*0PpyaRIcLhoyq8HX8Wh-ag.png',
        link: 'https://devayansarkar.medium.com/what-is-the-best-ide-for-coding-d4ea36e08f0',
        description: 'Modern day IDEs play a huge part in development, including correcting syntaxes, possibile errors and even bugs. But do we have to be dependent on IDEs ?',
        button: 'Read blog'
    }
]

export default blogs