import '../styles/footer.styles.sass'

import React from 'react'
const Footer = () => {
    return <footer className={'footer'}>
        <div className={'left'}>
            <span>© devayansarkar.com 2021</span>
            <br />
            <span>Devayan Kumar Sarkar</span>
        </div>
        <div className={'right'}>
            <a href={'https://www.linkedin.com/in/devayan-sarkar/'} target='_blank' rel="noreferrer">LinkedIn</a><br />
            <a href={'https://github.com/devayansarkar'} target="_blank" rel="noreferrer">GitHub</a><br />
            <a href={'mailto:me@devayansarkar.com'} target="_blank" rel="noreferrer">Email</a><br />
        </div>
    </footer>
}
export default Footer